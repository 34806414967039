import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getUserToken } from 'src/shared/utils/session'
import queryString from 'query-string'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: (headers, { getState }) => {
      const token = getUserToken()
      if (token) {
        headers.set('X-User-Token', token)
      }
      return headers
    }
  }),
  tagTypes: ['User', 'UserBom'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ q, page }) => {
        const defaultQuery = {
          'q[s]': 'created_at desc',
          'page': page,
        }
        return `users?${queryString.stringify(Object.assign({}, defaultQuery, q), { arrayFormat: 'bracket' })}`
      },
      providesTags: (result) => {
        if (result && result.count > 0) {
          return [...result.users.map(({ id }) => ({ type: 'User', id })), 'User']
        } else {
          return ['User']
        }
      },
    }),
    getUserBoms: builder.query({
      query: ({ q, page }) => `boms/user_boms?${q}&page=${page}&per_page=10`,
    }),
    getUserOrders: builder.query({
      query: ({ q, page }) => `orders/user_orders?${q}&page=${page}&per_page=10`,
    }),
    getUser: builder.query({
      query: (user_id) => `users/${user_id}`,
      providesTags: (_result, _error, arg) => [{ type: 'User', id: arg }],
    }),
    getCurrentUser: builder.query({
      query: () => 'current_user',
    }),
    // getCurrentUserConfigurations: builder.query({
    //   query: () => 'current_user/configurations',
    // }),
    getCurrentUserPreferences: builder.query({
      query: () => 'current_user/preferences',
    }),
    updateCurrentUserConfiguration: builder.mutation({
      query: (body) => {
        return {
          url: 'current_user/configurations',
          method: 'PUT',
          body: body,
        }
      },
    }),
    createUser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `users`,
          method: 'POST',
          body: {
            user: {
              ...formData,
            }
          }
        }
      },
      invalidatesTags: ['User'],
      transformErrorResponse: (response) => {
        if (response?.data?.errors) {
          return response.data.errors.join(', ')
        }

        return response
      },
    }),
    updateUser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `users/${formData.id}`,
          method: 'PUT',
          body: {
            user: {
              ...formData,
            }
          }
        }
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.id }],
    }),
    updateUserWithForm: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `users/${id}`,
          method: 'PUT',
          body: formData
        }
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.id }],
    }),
    updateCurrentUserPreferences: builder.mutation({
      query: (body) => {
        return {
          url: 'current_user/preferences',
          method: 'PUT',
          body: body,
        }
      },
    }),
  }),
})

export const {
  useGetUsersQuery,
  useLazyGetUserBomsQuery,
  // useLazyGetUserOrdersQuery,
  // useLazyGetUserQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserWithFormMutation,
  useCreateUserMutation,
  // useLazyGetCurrentUserConfigurationsQuery,
  useGetCurrentUserPreferencesQuery,
  // useLazyGetCurrentUserPreferencesQuery,
  // useGetCurrentUserQuery,
  // useLazyGetCurrentUserQuery,
  // useUpdateCurrentUserConfigurationMutation,
  useUpdateCurrentUserPreferencesMutation,
} = userApi