import React from "react";

import MainContent from "./components/MainContent";
import Distributors from './components/Distributors';
import WhyUse from "./components/WhyUse";
import EasyTools from "./components/EasyTools";
import HowBomSearchWorks from "./components/HowBomSearchWorks";
import EngineerSaid from "./components/EngineerSaid";
import JoinBeta from "./components/JoinBeta";

const HomePage = () => {

  return (
    <div id="welcome">
      <MainContent />
      <Distributors />
      <WhyUse />
      <EasyTools />
      <HowBomSearchWorks />
      <EngineerSaid />
      <JoinBeta />
    </div>
  )
}

export default HomePage;