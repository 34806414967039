import React from "react";
import { Input } from "@nextui-org/react";
import { Button } from 'src/shared/components/Button';

const FindPart = () => (

    <div className="flex gap-4">
      <Input classNames={{
        inputWrapper: 'h-12',
      }} placeholder="Enter a part number" />
      <Button color="warning" className=" px-6 h-12">Find Part</Button>
    </div>

)

export default FindPart;
